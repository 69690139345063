<template>
  <section class="m-comment m-scroll" ref="home" @scroll="handleScroll($event)">
    <!-- 头部导航 -->
    <m-header title="评价中心"></m-header>
    <!-- 标签页 -->
    <div class="tabs">
      <div class="tab-item" :class="{'tab-item-active':tab == 0}" @click="handleTab(0)">未评价({{num}})</div>
      <div class="tab-item" :class="{'tab-item-active':tab == 1}" @click="handleTab(1)">已评价</div>
      <div class="tab-active" ref="tabActive"></div>
    </div>
    <!-- 列表 -->
    <ul class="comment-list">
      <transition-group name="van-slide-down" v-if="tab == 0">
        <li class="comment-item" v-for="item in list" :key="item.id">
          <div class="item-content van-hairline--top van-hairline--bottom" v-for="(it, i) in item.order_goods" :key="i">
            <div class="content-left">
              <img :src="it.goods_sku_pic_url" alt="goods">
              </div>
            <div class="content-right">
              <p class="goods-title van-multi-ellipsis--l2">{{it.goods_name}}</p>
              <span>{{it.sku_name}}</span>
            </div>
          </div>
          <div class="item-foot m-padding">
            <div class="router-btn">
              <router-link :to="`/zh/order/detail/${item.id}`" class="btn">查看订单</router-link>
              <router-link :to="{path: '/zh/comment/submit', query: {id: item.id}}" class="btn btn-acitve">去评价</router-link>
            </div>
          </div>
        </li>
      </transition-group>
      <transition-group name="van-slide-down" v-else>
        <li class="comment-item" v-for="item in list" :key="item.id">
          <div class="item-content van-hairline--top van-hairline--bottom">
            <div class="content-left">
              <img :src="item.order_goods.goods_sku_pic_url" alt="goods">
              </div>
            <div class="content-right">
              <p class="goods-title van-multi-ellipsis--l2">{{item.order_goods.goods_name}}</p>
              <span>{{item.order_goods.sku_name}}</span>
            </div>
          </div>
          <div class="item-foot m-padding">
            <span class="time">{{item.created_at}}</span>
            <div class="router-btn">
              <!-- <router-link :to="{path: '/zh/comment/detail', query: {id: item.id, flag: 1}}" class="btn">追加评价</router-link> -->
              <router-link :to="{path: '/zh/comment/detail', query: {id: item.id, flag: 2}}" class="btn">查看评价</router-link>
            </div>
          </div>
        </li>
      </transition-group>
      <p v-if="list.length == 0 && !loading" class="no-more">暂无数据</p>
    </ul>
    <van-loading v-show="loading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { comment_list } from '@/api/zh/order.js'
export default {
  name:'Index',
  components:{ MHeader, },
  data(){
    return {
      tab: 0,          // tabs选中的面板
      loading: false,
      num: 0,
      list:[],
      page: 1
    }
  },
  mounted(){
    this.tab = 0
    this.getData()
  },
  methods:{
    // 内容滑动事件
    handleScroll(e){
      if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 50){
        if(!this.islock){
          e.preventDefault()
          e.stopPropagation()
          this.islock   = false
          this.page++
          this.getData()
        }
        this.islock = true
      }
    },
    // 切换 tab
    handleTab(tab){
      let left = 20.73333 + tab  * 50
      this.$refs.tabActive.style.left = left + 'vw'
      this.tab = tab
      this.page = 1
      this.list = []
      this.getData()
    },
    getData() {
      this.loading = true
      comment_list({evaluate_status: this.tab, page: this.page}).then(res => {
        if(res) {
          this.list = this.list.concat(res.data.data)
          if(this.tab == 0 ) {
            this.num = res.data.total
          }
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import './list.less';
</style>